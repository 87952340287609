<template>
  <div class="container-fluid">
    <div class="card">
      <div class="card-body h-100">
        <div class="mb-3">
          <h3>
            สินค้า {{ inputsnode.totalRows }} ชิ้น
          </h3>
          <div class="separator" />
        </div>
        <div class="row justify-content-between">
          <div class="col-md-6 col-xl-2 mb-1">
            <b-button
              block
              variant="success"
              style="height: 38px;"
              @click="onclickRouterAdd"
            >
              เพิ่มสินค้า
            </b-button>
          </div>
          <div class="col-md-6 col-xl-3  mb-1">
            <b-form-group style="margin-bottom: 0rem;">
              <div class="d-flex align-items-center">
                <label class="mr-1">ค้นหา</label>
                <b-form-input
                  v-model="searchTerm"
                  placeholder="ค้นหา"
                  type="text"
                  class="d-inline-block"
                />
              </div>
            </b-form-group>
          </div>
        </div>
        <b-table
          striped
          hover
          responsive
          class="position-relative"
          :per-page="inputsnode.perPage"
          :current-page="inputsnode.currentPage"
          :items="datas"
          :fields="fields"
          :filter="searchTerm"
          show-empty
          @filtered="onFiltered"
        >
          <template #cell(price)="data">
            <div v-if="data.item.promotion.length > 0">
              <div v-if="data.item.promotion[0].type === 'D'">
                <p class="font-weight-bolder">
                  {{ [{price:data.item.product.price.$numberDecimal,percen:data.item.promotion[0].value.$numberDecimal}] | toPercen }} <b-badge variant="danger">
                    {{ `${data.item.promotion[0].value.$numberDecimal} %` }}
                  </b-badge>
                </p>
                <p style="margin-top:-5px">
                  <s>{{ data.item.product.price.$numberDecimal|toCurrency }}</s>
                </p>

              </div>
            </div>
            <div v-else>
              <p>{{ data.item.product.price.$numberDecimal|toCurrency }}</p>
            </div>
          </template>
          <template #cell(amount)="data">
            <p>{{ data.item.total.$numberDecimal|toAmount }}</p>
          </template>
          <template #cell(button)="data">
            <div class="d-grid d-md-block">
              <button
                class="btn btn-primary mr-1"
                type="button"
                @click="onClickProductAdd(data.item.product.code)"
              >
                เพิ่ม
              </button>
              <button
                class="btn btn-info mr-1"
                type="button"
                @click="onClickProductTranfer(data.item.product.code)"
              >
                โอน
              </button>
              <button
                class="btn btn-warning mr-1"
                type="button"
                @click="onClickProductEdit(data.item.product.code)"
              >
                แก้ไข
              </button>
              <button
                class="btn btn-danger mr-1"
                type="button"
                @click="onClickProductDelete(data.item.product._id, data.item.product.name)"
              >
                ลบ
              </button>

            </div>
          </template>
          <template #empty>
            <div class="col-md-12 text-center m-1">
              <h4>ไม่พบข้อมูลสินค้า</h4>
            </div>
          </template>
          <template #emptyfiltered>
            <div class="col-md-12 text-center m-1">
              <h4>ไม่พบข้อมูลสินค้า</h4>
            </div>
          </template>
        </b-table>
        <b-card-body
          v-if="totalItem > 0"
          class="d-flex justify-content-between flex-wrap pt-0 mt-2"
        >
          <b-form-group
            label="Per Page"
            label-cols="6"
            label-align="left"
            label-size="sm"
            label-for="sortBySelect"
            class="text-nowrap mb-md-0 mr-1"
          >
            <b-form-select
              id="perPageSelect"
              v-model="inputsnode.perPage"
              size="sm"
              inline
              :options="inputsnode.pageOptions"
            />
          </b-form-group>

          <div>
            <b-pagination
              v-model="inputsnode.currentPage"
              :total-rows="inputsnode.totalRows"
              :per-page="inputsnode.perPage"
              first-number
              last-number
              prev-class="prev-item"
              next-class="next-item"
              class="mb-0"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </div>
        </b-card-body>
      </div>
    </div>
  </div>

</template>
<script>
import { defineComponent } from '@vue/composition-api'
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import {
  BTable, BAvatar, BBadge, BFormGroup, BFormSelect, BPagination, BInputGroup, BFormInput, BInputGroupAppend, BButton, BCardBody
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import router from '@/router'

export default defineComponent({
  name: 'product_list',
  components: {
    BTable,
    BAvatar,
    BBadge,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BFormSelect,
    BPagination,
    BCardBody,
    BFormGroup,
    BCardCode
  },
  watch: {
  },
  computed: {
    datas () {
      return this.$store.state.production.data.length > 0 ? this.$store.state.production.data : []
    },
    totalItem () {
      return this.inputsnode.totalRows
    }
  },
  data () {
    return {
      searchTerm: '',
      items: [],

      fields: [
        {
          key: 'product.code', label: 'รายการ', tdClass: 'text-center', thClass: 'text-center', thStyle: 'min-width: 160px'
        },
        {
          key: 'product.name', label: 'ชื่อรายการ', tdClass: 'avatarna', thStyle: 'min-width: 200px'
        },
        { key: 'price', label: 'ราคา', thStyle: 'min-width: 220px', thClass: 'text-center', tdClass: 'text-right' },
        { key: 'amount', label: 'คงเหลือ', sortable: true, thClass: 'text-center', tdClass: 'text-right', thStyle: 'min-width: 120px' },
        { key: 'button', label: '', thStyle: 'min-width: 390px' }

      ],
      inputsnode: {
        perPage: 10,
        pageOptions: [10, 25, 50],
        totalRows: 0,
        itemsPerPage: 10,
        currentPage: 1,
        startItem: 0,
        endItem: 10
      }

    }
  },
  methods: {

    onClickProductDelete (val, name) {
      this.$swal({
        title: 'คุณต้องการจะลบข้อมูลนี้?',
        text: 'คุณมั่นใจรึเปล่า ? คุณจะไม่สามารถนำข้อมูลนี้กลับมาได้!',
        type: 'warning',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonClass: 'btn-success mr-1',
        confirmButtonText: 'ยืนยัน',
        cancelButtonClass: 'btn-danger',
        cancelButtonText: 'ยกเลิก'
      }).then(result => {
        if (result.value) {
          this.$store.dispatch('production/DeleteProduct', { id: val, name }).then(response => {
            console.log(response)
            if (response.status === 200) {
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: 'success',
                  icon: 'CoffeeIcon',
                  variant: 'success',
                  text: response.data.message
                }
              })
              this.loader()
            } else {
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: 'error',
                  icon: 'CoffeeIcon',
                  variant: 'danger',
                  text: response.data.message
                }
              })
            }
          })
        }
      })
    },
    onFiltered (filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.inputsnode.totalRows = filteredItems.length
      this.inputsnode.currentPage = 1
    },
    onclickRouterAdd () {
      router.push('/product/add')
    },
    loader () {
      this.$store.dispatch('production/getProductAll').then(result => {
        console.log('allproduct', result)
        this.inputsnode.totalRows = this.$store.state.production.data.length
      }).catch(error => {
        console.log(error)
      })
    },
    onClickProductEdit (val) {
      router.push(`/product/add/${val}/edit`)
    },
    onClickProductAdd (val) {
      router.push(`/product/add/${val}/add`)
    },
    onClickProductTranfer (val) {
      router.push(`/product/tranfer/${val}`)
    }
  },
  created () {
    this.loader()
  }
})
</script>
